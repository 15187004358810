<template>
    <div class="order-details-modal-container animation-open-modal" v-if="open">
        <header>
            <div class="header-container">
                <div class="header-container-left">
                    <div @click="backToOrderList">
                        <slot name="back-button"/>
                    </div>
                    <h1>{{ `${orderData.order_no}/${orderData.op_no}` }}</h1>
                </div>
                <button @click="closeModal">
                    <CloseIcon v-if="windowWidth > 425 && windowWidth < 768" />
                    <CloseIconSmall v-else />
                </button>
            </div>

            <div class="graph-buttons-resources">
                <button type="button" :class="[
                    'btn-graph-resources',
                    showInformation
                        ? 'btn-active'
                        : 'btn-disabled',
                ]"
                    @click="() => { showInformation = true; showProgress = false; showProductionProgress = false; showLinks = false; }">
                    {{ $t('Informations') }}
                </button>

                <button type="button" :class="[
                    'btn-graph-resources',
                    showProgress
                        ? 'btn-active'
                        : 'btn-disabled',
                ]"
                    @click="() => { showProgress = true; showInformation = false; showProductionProgress = false; showLinks = false; }">
                    {{ $t('Progress') }}
                </button>

                <button type="button" :class="[
                    'btn-graph-resources',
                    showProductionProgress
                        ? 'btn-active'
                        : 'btn-disabled',
                ]"
                    @click="() => { showProductionProgress = true; showProgress = false; showInformation = false; showLinks = false; }">
                    {{ $t('Production') }}
                </button>

                <button type="button" :class="[
                    'btn-graph-resources',
                    showLinks
                        ? 'btn-active'
                        : 'btn-disabled',
                ]"
                    @click="() => { showLinks = true; showProductionProgress = false; showProgress = false; showInformation = false }">
                    {{ $t('Links') }}
                </button>
            </div>
        </header>

        <main>
            <!-- TODO: componentes serão renderizados aqui -->
            <InfoTab v-if="showInformation" :data="componentsProps.infoTab" />
            <ProgressTab v-if="showProgress" :data="componentsProps.progressTab" />
            <TabLinks v-show="showLinks" :list.sync="linksList" />
            <TabNote v-if="showProductionProgress" :list="listOperationProgress"
            :isMultipleOrders="isMultipleOrders" :isControlByHour="isControlByHour" @deleteOperationProgress="handleAfterDeleteAppointment()" />
        </main>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    components: {
        CloseIcon: () => import('@/assets/images/icons/close-icon-1.svg'),
        CloseIconSmall: () => import('@/assets/images/icons/close-icon.svg'),
        TabLinks: () => import('@/views/pages/operator/components/TabLinks/TabLinks.vue'),
        TabNote: () => import('@/views/pages/operator/components/TabNote/TabNote.vue'),
        InfoTab: () => import('@/views/pages/operator/components/DetailsModal/components/InfoTab'),
        ProgressTab: () => import('@/views/pages/operator/components/DetailsModal/components/ProgressTab'),
    },
    props: {
        orderData: {
            type: Object,
            default: () => ({}),
            required: true
        },
        open: {
            type: Boolean,
            default: false
        },
        isMultipleOrders: {
            type: Boolean,
            default: false
        },
        isControlByHour: {
            type: Boolean,
            default: false
        }
    },
    destroyed() {

    },
    mounted() {
        if (this.open)
            this.addOpenAnimation();

        this.formatPropsInfoTab();
        this.formatPropsProgressTab();
        this.getLinks();
        this.getOperationProgress();

        $(function () {
            const slider = document.querySelector('.graph-buttons-resources');
            let mouseDown = false;
            let startX, scrollLeft;

            let startDragging = function (e) {
                mouseDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            };
            let stopDragging = function (event) {
                mouseDown = false;
            };

            slider.addEventListener('mousemove', (e) => {
                e.preventDefault();
                if (!mouseDown) { return; }
                const x = e.pageX - slider.offsetLeft;
                const scroll = x - startX;
                slider.scrollLeft = scrollLeft - scroll;
            });

            slider.addEventListener('mousedown', startDragging, false);
            slider.addEventListener('mouseup', stopDragging, false);
            slider.addEventListener('mouseleave', stopDragging, false);

            $('.b-form-tags-input').blur((event) => {
                let input = vm.$refs.formTagOrders;
                input.addTag(event.target.value);
            })
        });
    },
    data: () => ({
        windowWidth: window.innerWidth,
        showInformation: true,
        showProgress: false,
        showProductionProgress: false,
        showLinks: false,
        hasDeletedAppointment: false,
        componentsProps: {
            infoTab: {},
            progressTab: {},
            linksTab: {},
            noteTabs: {}
        },
        prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,
        currentSite: JSON.parse(localStorage.getItem("currentSite")),
        linksList: [],
        listOperationProgress: []
    }),
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        ...mapActions('app', [
            'removeRequestLoading'
        ]),
        handleAfterDeleteAppointment() {
            this.getOperationProgress();

            if (this.$router.currentRoute.params?.appointment_type == 1) {
                this.hasDeletedAppointment = true;
            }
        },
        addOpenAnimation() {
            const modal = document.querySelector('.order-details-modal-container');
            modal.classList.add('animation-open-modal');

            setTimeout(() => document.querySelector('.order-details-modal-container').classList.remove('animation-open-modal'), 1000);
        },
        closeAnimation() {
            const modal = document.querySelector('.order-details-modal-container');
            modal.classList.add('animation-close-modal');
            modal.classList.add('closed-modal');

            setTimeout(() => this.$emit('close', false), 500);
        },
        closeModal() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.closeAnimation();
            this.$emit('closeListModal');

            this.$router.currentRoute.params?.appointment_type == 1 && this.checkHasReworkAppointments();
        },
        checkHasReworkAppointments() {
            const { params } = this.$router.currentRoute;
            const hasReworkAppointment = this.listOperationProgress.find(appointment => appointment.is_rework == 1);

            if (this.hasDeletedAppointment && !hasReworkAppointment)
                setTimeout(() => {
                    this.$router.push({
                        name: "operator-production-progress",
                        params: {
                            ...params,
                            appointment_type: 0
                        }
                    })
                    this.$router.go();
                },500)
        },
        backToOrderList() {
            this.closeAnimation();
        },
        formatFloatStringForInt(value) {
            return parseFloat(value).toFixed();
        },
        formatPercentStringForIntNumber(value) {
            const percent = 100;
            return Number((parseFloat(value) * percent).toFixed());
        },
        formatPercentStringForFloatNumber(value) {
            const percent = 100;
            return (Number(value) * percent).toFixed(2);
        },
        formatHour(value) {
            return parseFloat(value).toFixed(2).replace('.', ',');
        },
        formatHourAndMinute(value) {
            let number = Number(value);
             // Check sign of given number
            let sign = (number >= 0) ? 1 : -1;

            // Set positive value of number of sign negative
            number = number * sign;

            // Separate the int from the decimal part
            const hour = Math.floor(number);
            let decpart = number - hour;

            const min = 1  / 60;
            // Round to nearest minute
            decpart = min * Math.round(decpart / min);

            let minute = Math.floor(decpart * 60) + '';

            // Add padding if need
            minute = minute.length < 2 ? '0' + minute : minute;

            // Add Sign in final result
            sign = sign == 1 ? '' : '-';

            // Concate hours and minutes
            return `${sign}${hour} h ${minute} m`;
        },
        formatPropsInfoTab() {
            const { orderData } = this;

            const observation = !orderData.note?.includes('table')
                ? orderData.note
                : orderData.note
                    .replaceAll("<table", '<div style="overflow: hidden; overflow-x: scroll"><table')
                    .replaceAll("</table>", "</table></div>");

            const setAttributes = () => {
                const createdValue = {};

                for (const [key, value] of Object.entries(orderData.attributes)) {
                    createdValue[key] = {
                        value: value,
                        truncate: true
                    }
                }

                return createdValue;
            }

            this.componentsProps.infoTab = {
                Order: {
                    Quantity: {
                        value: this.formatFloatStringForInt(orderData.planned_quantity),
                        truncate: false
                    },
                    Priority: {
                        value: this.formatFloatStringForInt(orderData.priority),
                        truncate: false
                    },
                    EarlyStartDate: {
                        value: orderData.earliest_start_date,
                        truncate: false
                    },
                    DueDate: {
                        value: orderData.due_date,
                        truncate: false
                    }
                },
                Operation: {
                    OperationNo: {
                        value: orderData.op_no,
                        truncate: false
                    },
                    OperationName: {
                        value: orderData.operation_name,
                        truncate: true
                    },
                    ProductCode: {
                        value: orderData.part_no,
                        truncate: false
                    },
                    ProductName: {
                        value: orderData.product,
                        truncate: true
                    },
                    Resource: {
                        value: orderData.resource_name,
                        truncate: true
                    },
                    Workcenter: {
                        value: orderData.workcenter,
                        truncate: true
                    },
                    ForecastOperationSetup: {
                        value: orderData.setup_start,
                        truncate: false
                    },
                    ForecastOperationStart: {
                        value: orderData.start_time,
                        truncate: false
                    },
                    ForecastOperationEnd: {
                        value: orderData.end_time,
                        truncate: false
                    }
                },
                Attributes: setAttributes(),
                Observations: observation
            }
        },
        formatPropsProgressTab() {
            const { orderData, isControlByHour } = this;

            const setProgressInfoData = () => (
                isControlByHour
                    ? {
                        forecastTime: {
                            productiveTime: this.formatHourAndMinute(orderData.produtive_planned_time),
                            total: this.formatHourAndMinute(orderData.total_planned_time)
                        },
                        myWork: {
                            setup: this.formatHourAndMinute(orderData.progress_time_setup),
                            running: this.formatHourAndMinute(orderData.progress_time_running),
                            stopped: this.formatHourAndMinute(orderData.progress_time_stopped)
                        },
                        totalTime: {
                            netTime: this.formatHourAndMinute(orderData.total_lead_time),
                            completed: this.formatHourAndMinute(orderData.total_running_time),
                        }
                    }
                    : {
                        quantity: {
                            planned: this.formatFloatStringForInt(orderData.planned_quantity),
                            produced: this.formatFloatStringForInt(orderData.produced_quantity),
                            rework: this.formatFloatStringForInt(orderData.rework_quantity),
                            scrap: this.formatFloatStringForInt(orderData.refuse_quantity),
                        },
                    }
            );

            const formatStatus = (list) => list.map(status => ({
                ...status,
                percent: this.formatPercentStringForFloatNumber(status.percent),
                hours: this.formatHour(status.hours)
            }))

            this.componentsProps.progressTab = {
                progressData: {
                    theoretical_progress: this.formatPercentStringForIntNumber(orderData.theoretical_progress),
                    real_progress: this.formatPercentStringForIntNumber(orderData.real_progress)
                },
                progressInfoData: setProgressInfoData(),
                statusData: formatStatus(orderData.status)
            }
        },
        getLinks() {
            const configs = {
                headers: {
                    ignoredRequest: true
                }
            };

            this.$http.get(`/api/${this.prefix}/order/links?site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}&preactor_order_id=${this.orderData.preactor_order_id}`, configs)
                .then(res => {
                    this.linksList = res.data;
                })
        },
        getOperationProgress() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http.get(`/api/${this.prefix}/operator/operation-progress?preactorOrderId=${this.orderData.preactor_order_id}&site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}`)
                .then((res) => {
                    this.listOperationProgress = res.data;

                    //manter essa linha pois quando apaga um apontamento esse método é chamado para atualizar a listagem, e loading só pode sumir quando popular a lista novamente
                    this.removeRequestLoading();
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.header-container {
    display: flex;
    align-items: center;
    padding: 16px 29px 15px;
    gap: 10px;
    background: #FFFFFF;
    border-bottom: 1px solid #CFC4BE;
    justify-content: space-between;

    .header-container-left {
        display: flex;
        align-items: center;
    }

    h1 {
        margin: 0;
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #4C4541;
    }

    button {
        padding: 0;
        border: none;
        background: none;
    }
}

.graph-buttons-resources {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 58px;
    overflow-x: auto;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0);

    &::-webkit-scrollbar {
        display: none !important;
    }

    .btn-graph-resources {
        font-size: 16px;
        border: none;
    }

    .btn-active {
        width: 100%;
        background-color: transparent;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #974900;
        box-shadow: inset 0px -4px 0px #974900;
        min-width: 192px;
    }

    .btn-disabled {
        width: 100%;
        background-color: transparent;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #998F8A;
        border-bottom: 1px solid #CFC4BE;
        min-width: 192px;
    }
}

.order-details-modal-container {
    height: 100dvh;
    width: 100vw;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9991;
    display: flex;
    flex-direction: column;

    main {
        overflow: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color:  #CFC4BE;
            border-radius: 9px;
        }
    }

    &.animation-open-modal {
        animation: openModal .5s normal;

        @keyframes openModal {
            0% {
                top: 100vh;
            }

            100% {
                top: 0;
            }
        }
    }


    &.animation-close-modal {
        animation: closeModal .5s normal;

        @keyframes closeModal {
            0% {
                top: 0;
            }

            100% {
                top: 100vh;
            }
        }
    }

    &.closed-modal {
        top: 100vh;
    }

    .list-content {
        &::-webkit-scrollbar {
            width: 16px;
            /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #CFC4BE;
            /* color of the scroll thumb */
            border-radius: 20px;
            /* roundness of the scroll thumb */
            border: 4px solid #fff;
            height: 70px;
        }

        overflow: auto;
        padding: 0;
        margin: 0;
    }
}

.container-components {
    padding: 24px 29px;
}

@media (max-width: 768px) {
    .header-container {
        h1 {
            font-size: 36px;
            line-height: 50px;
        }
    }

    .graph-buttons-resources {
        .btn-active {
            font-size: 18px;
        }
    }
}

@media (max-width: 425px) {
    .header-container {
        padding: 15px 16px 14px;

        h1 {
            font-size: 18px;
            line-height: 26px !important;
        }
    }

    .graph-buttons-resources {
        .btn-active {
            font-size: 16px;
        }

        .btn-disabled {
            font-size: 16px;
        }
    }

    .container-components {
        padding: 24px 16px;
    }
}
</style>
